/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from "@material-ui/core";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AddPropertyComponent from "../../components/PropertyModal/add-property-component";
import UpdateProperty from "../../components/PropertyModal/update-property";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import {
  useAddPropertyMutation,
  usePropertyList,
  useUpdatePropertyMutation,
} from "../../hooks/property.hooks";
import LinkProperty from "./link-property";
import { buildErrorMessage } from "../../utils/property-util";

const LinkPropertyModal = ({
  open = false,
  accountId = "",
  handleDialogClose = null,
}) => {
  const [params, setParams] = useState({
    accountIds: "",
  });
  const { setSelectedPropertyId } = useContext(SelectedPropertyContext);
  const { data: propertyList } = usePropertyList();
  const [selectedLinkProperty, setSelectedLinkProperty] = useState("");

  const selectedProperty = useMemo(() => {
    return propertyList.find(
      (property) => property.propertyId === selectedLinkProperty
    );
  }, [selectedLinkProperty]);

  useEffect(() => {
    if (open && params.accountIds !== accountId) {
      setParams((prevParams) => ({ ...prevParams, accountIds: accountId }));
    }
  }, [accountId, open]);

  useEffect(() => {
    if (selectedProperty?.accountIds) {
      setParams((prevParams) => ({
        ...prevParams,
        purpose: selectedProperty?.purpose,
        accountIds: `${selectedProperty.accountIds},${accountId}`,
      }));
    }
    setParams((prevParams) => ({
      ...prevParams,
      purpose: selectedProperty?.purpose,
    }));
  }, [selectedProperty]);

  const handleChange = (event) => {
    setSelectedLinkProperty(event.target.value);
  };
  const [
    isProvidePropertyInformation,
    setIsProvidePropertyInformation,
  ] = useState(false);
  const [isMissingData, setIsMissingData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const addProperty = useAddPropertyMutation();
  const updateProperty = useUpdatePropertyMutation();

  const handleUpdateParams = (value) => {
    setParams((prevParams) => ({ ...prevParams, ...value }));
  };

  const handleClose = () => {
    setParams({
      accountIds: "",
    });
    setSelectedLinkProperty("");
    setIsProvidePropertyInformation(false);
    handleDialogClose(false);
  };

  const handleAddProperty = () => {
    addProperty.mutate(params, {
      onSuccess: (result) => {
        setSelectedPropertyId(result.propertyId);
        handleClose();
        enqueueSnackbar("The property has been added", { variant: "success" });
      },
      onError: (error) => {
        const { data: { message = "", statusCode } = {} } = error;
        if (statusCode === 400 && message !== "Property is already existed") {
          setIsProvidePropertyInformation(true);
          setIsMissingData(true);
        }
        enqueueSnackbar(
          buildErrorMessage(
            message === "Property is already existed"
              ? message
              : "Missing required fields."
          ),
          { variant: "error" }
        );
      },
    });
  };

  const handleLinkProperty = () => {
    updateProperty.mutate(
      { params, id: selectedLinkProperty },
      {
        onSuccess: (result) => {
          setSelectedPropertyId(result.propertyId);
          handleClose();
          enqueueSnackbar("The property has been linked", {
            variant: "success",
          });
        },
      }
    );
  };

  const renderAddOrUpdateProperty = () => {
    return isProvidePropertyInformation ? (
      <UpdateProperty
        isMissingData={isMissingData}
        params={params}
        handleUpdateParams={handleUpdateParams}
        handleUpdateProperty={handleAddProperty}
        isLoading={addProperty.isLoading}
      />
    ) : (
      <AddPropertyComponent
        params={params}
        isLoading={addProperty.isLoading}
        handleUpdateParams={handleUpdateParams}
        handleAddProperty={handleAddProperty}
      />
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="property-modal"
    >
      <div className="property-modal__container">
        {selectedLinkProperty === "Add Property" ? (
          renderAddOrUpdateProperty()
        ) : (
          <LinkProperty
            value={selectedLinkProperty}
            isLoading={updateProperty.isLoading}
            handleChange={handleChange}
            handleLinkProperty={handleLinkProperty}
          />
        )}
      </div>
    </Dialog>
  );
};

LinkPropertyModal.propTypes = {
  open: PropTypes.bool,
  handleDialogClose: PropTypes.func,
};

export default LinkPropertyModal;
