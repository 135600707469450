import PropTypes from "prop-types";
import React, { useState } from "react";
import PropertyModal from "../../components/PropertyModal";
import { primaryColor } from "../../scss/colors.scss";
import Plus from "../../svgs/Plus";
import LinkPropertyModal from "./link-property-modal";

const AddPropertyButton = ({ accountId = "", title = "Add Property" }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogToggle = (value) => {
    setDialogOpen(value);
  };
  return (
    <>
      <div
        className="la-container add-account"
        onClick={() => handleDialogToggle(true)}
      >
        <div>{title}</div>
        <div className="plus-icon">
          <Plus fill={primaryColor} />
        </div>
      </div>
      {title === "Link Property" ? (
        <LinkPropertyModal
          accountId={accountId}
          open={dialogOpen}
          handleDialogClose={handleDialogToggle}
        />
      ) : (
        <PropertyModal
          accountId={accountId}
          open={dialogOpen}
          handleDialogClose={handleDialogToggle}
        />
      )}
    </>
  );
};

AddPropertyButton.propTypes = {
  accountId: PropTypes.string,
};

export default AddPropertyButton;
