export const buildErrorMessage = (message) => {
  switch (true) {
    case message.indexOf("property_type") > -1:
      return "Your property type value must be one of: House, Unit";
    case message.indexOf("landarea") > -1:
      return "Land size value must be greater than 50";
    case message.indexOf("bedrooms") > -1:
      return "Bedrooms value must not be empty";
    case message.indexOf("carparks") > -1:
      return "Carparks value must not be empty";
    case message.indexOf("Bathrooms") > -1:
      return "Bathrooms value must not be empty";

    default:
      return message;
  }
};
