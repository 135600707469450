import { FormControl, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { CustomButtonOutLine } from "../../components/custom-button";
import { usePropertyList } from "../../hooks/property.hooks";
import { titleCase } from "../../utils/text-format";

const LinkProperty = ({
  handleLinkProperty,
  value,
  handleChange,
  isLoading = false,
}) => {
  const { data: propertyList } = usePropertyList();
  const renderPropertyMenuItem = () => {
    return propertyList.map((property) => {
      const { propertyId = "", address = "" } = property;
      return (
        <MenuItem
          className="link-property__menu-item"
          key={propertyId}
          value={propertyId}
        >
          {titleCase(address)}
        </MenuItem>
      );
    });
  };
  return (
    <div className="link-property">
      <div>Select Property</div>
      <FormControl>
        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {renderPropertyMenuItem()}
          <MenuItem
            key="Add Property"
            value="Add Property"
            className="link-property__menu-item"
          >
            Add New Property
          </MenuItem>
        </Select>
      </FormControl>
      <CustomButtonOutLine
        disabled={isLoading}
        isLoading={isLoading}
        label="Link Property"
        buttonType="secondary"
        onClick={handleLinkProperty}
      />
    </div>
  );
};

LinkProperty.propTypes = {
  value: PropTypes.string,
  handleLinkProperty: PropTypes.func,
  handleChange: PropTypes.func,
};

export default LinkProperty;
