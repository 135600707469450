import PropTypes from "prop-types";
import React from "react";
import { PROPERTY_PURPOSE } from "../../commons/enum";
import AddPropertyInput from "../add-property-input";
import CustomSelectButton from "./custom-select-button";

const AddPropertyComponent = ({
  params,
  handleUpdateParams,
  handleAddProperty,
  isLoading = false,
}) => {
  return (
    <>
      <div className="add-property-usage">
        <span className="add-property-usage__title">Property Usage</span>
        <div
          className="add-property-usage__button-group"
          variant="outlined"
          aria-label="outlined button group"
        >
          <CustomSelectButton
            label="Investor"
            isSelected={params.purpose === PROPERTY_PURPOSE.INVEST}
            handleUpdateParams={() =>
              handleUpdateParams({
                purpose: PROPERTY_PURPOSE.INVEST,
              })
            }
          />
          <CustomSelectButton
            label="Owner Occupier"
            isSelected={params.purpose === PROPERTY_PURPOSE.OCCUPY}
            handleUpdateParams={() =>
              handleUpdateParams({
                purpose: PROPERTY_PURPOSE.OCCUPY,
              })
            }
          />
        </div>
      </div>
      <div className="add-property-input">
        <span>Add property</span>
        <AddPropertyInput
          isLoading={isLoading}
          handleUpdateParams={handleUpdateParams}
          handleAddProperty={handleAddProperty}
        />
      </div>
    </>
  );
};

AddPropertyComponent.propTypes = {
  params: PropTypes.object,
  handleUpdateParams: PropTypes.func,
  handleAddProperty: PropTypes.func,
};

export default AddPropertyComponent;
