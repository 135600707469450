import { Dialog } from "@material-ui/core";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { PROPERTY_PURPOSE } from "../../commons/enum";
import { SelectedPropertyContext } from "../../context/selected-property-context";
import { useAddPropertyMutation } from "../../hooks/property.hooks";
import AddPropertyComponent from "./add-property-component";
import UpdateProperty from "./update-property";
import { buildErrorMessage } from "../../utils/property-util";

const PropertyModal = ({
  open = false,
  accountId = "",
  handleDialogClose = null,
}) => {
  const [params, setParams] = useState({
    accountIds: accountId,
    purpose: PROPERTY_PURPOSE.INVEST,
  });
  const { setSelectedPropertyId } = useContext(SelectedPropertyContext);
  const [
    isProvidePropertyInformation,
    setIsProvidePropertyInformation,
  ] = useState(false);
  const [isMissingData, setIsMissingData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const addProperty = useAddPropertyMutation();

  const handleUpdateParams = (value) => {
    setParams((prevParams) => ({ ...prevParams, ...value }));
  };

  const handleClose = () => {
    setParams({
      accountIds: accountId,
      purpose: PROPERTY_PURPOSE.INVEST,
    });
    setIsProvidePropertyInformation(false);
    handleDialogClose(false);
  };

  const handleAddProperty = () => {
    addProperty.mutate(params, {
      onSuccess: (result) => {
        setSelectedPropertyId(result.propertyId);
        handleClose();
        enqueueSnackbar("The property has been added", { variant: "success" });
      },
      onError: (error) => {
        const {
          data: { message = "", statusCode, propertyDetail } = {},
        } = error;

        if (statusCode === 400 && message !== "Property is already existed") {
          const {
            bathrooms = 0,
            bedrooms = 0,
            carparks = 0,
            landarea = 0,
            propertyType,
          } = propertyDetail;
          setParams((prevParam) => {
            return {
              ...prevParam,
              bathrooms,
              bedrooms,
              carparks,
              landarea,
              propertyType,
            };
          });
          setIsProvidePropertyInformation(true);
          setIsMissingData(true);
        }
        enqueueSnackbar(
          buildErrorMessage(
            message === "Property is already existed"
              ? message
              : "Missing required fields."
          ),
          { variant: "error" }
        );
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className="property-modal"
    >
      <div className="property-modal__container">
        {isProvidePropertyInformation ? (
          <UpdateProperty
            isMissingData={isMissingData}
            params={params}
            handleUpdateParams={handleUpdateParams}
            handleUpdateProperty={handleAddProperty}
            isLoading={addProperty.isLoading}
          />
        ) : (
          <AddPropertyComponent
            params={params}
            isLoading={addProperty.isLoading}
            handleUpdateParams={handleUpdateParams}
            handleAddProperty={handleAddProperty}
          />
        )}
      </div>
    </Dialog>
  );
};

PropertyModal.propTypes = {
  open: PropTypes.bool,
  handleDialogClose: PropTypes.func,
};

export default PropertyModal;
